import React from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

import styles from '../../styles/global/typography.module.scss'

const Heading = ({
  padding,
  margin,
  className,
  level,
  size,
  align,
  color,
  weight,
  children,
}) => {
  const Element = `h${level}`

  return (
    <Element
      className={classnames(
        spacing(padding, margin),
        className,
        styles[`h${level}`],
        size && styles[`h${size}`],
        styles[align],
        styles[color],
        styles[weight]
      )}
    >
      {children}
    </Element>
  )
}

Heading.defaultProps = {
  className: '',
  size: null,
  align: null,
  color: null,
  weight: null,
}

Heading.propTypes = {
  ...spacingPropTypes,
  className: PropTypes.string,
  level: PropTypes.oneOf([1, 2, 3, 4, 5, 6]).isRequired,
  size: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.oneOf([
    'black',
    'white',
    'primary',
    'secondary',
    'grey',
    'lightGrey',
    'darkGrey',
  ]),
  weight: PropTypes.oneOf(['light', 'regular', 'bold', 'heavy']),
  children: PropTypes.node.isRequired,
}

export default Heading
