import React from 'react'
import PropTypes from 'prop-types'
import * as classnames from 'classnames'

import typographyStyles from '../../styles/global/typography.module.scss'

import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

const Text = ({
  element,
  padding,
  margin,
  size,
  align,
  color,
  weight,
  letterSpacing,
  className,
  isUppercase,
  flexGrow,
  children,
}) => {
  const Element = element

  return (
    <Element
      className={classnames(
        spacing(padding, margin),
        typographyStyles.p,
        typographyStyles[size],
        typographyStyles[align],
        typographyStyles[color],
        typographyStyles[weight],
        typographyStyles[`spacing${letterSpacing}`],
        className,
        isUppercase && typographyStyles.uppercase,
        flexGrow && typographyStyles.flexGrow
      )}
    >
      {children}
    </Element>
  )
}

Text.defaultProps = {
  element: 'p',
  size: 'large',
  align: null,
  color: null,
  weight: null,
  letterSpacing: null,
  className: null,
  isUppercase: false,
  flexGrow: false,
}

Text.propTypes = {
  ...spacingPropTypes,
  element: PropTypes.oneOf(['p', 'span', 'div', 'li', 'ol']),
  size: PropTypes.oneOf(['xSmall', 'small', 'medium', 'large', 'xLarge']),
  align: PropTypes.oneOf(['left', 'center', 'right']),
  color: PropTypes.oneOf([
    'black',
    'white',
    'primary',
    'secondary',
    'grey',
    'midGrey',
    'lightGrey',
    'darkGrey',
  ]),
  weight: PropTypes.oneOf(['light', 'regular', 'bold', 'heavy']),
  letterSpacing: PropTypes.oneOf([1, 2, 3]),
  className: PropTypes.string,
  isUppercase: PropTypes.bool,
  flexGrow: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default Text
