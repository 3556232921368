import React from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import Link from '../Link/Link'
import Text from '../Text/Text'

import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

import buttonStyles from '../../styles/elements/button.module.scss'
import typographyStyles from '../../styles/global/typography.module.scss'

const Button = ({
  type,
  to,
  icon,
  iconColor,
  className,
  padding,
  margin,
  ariaLabel,
  isBlock,
  isDisabled,
  shouldSubmit,
  onClick,
  children,
}) => {
  const Icon = icon
  let classes = []

  const Content = () => (
    <>
      {icon && (
        <Icon
          className={classnames(
            buttonStyles.icon,
            typographyStyles[iconColor],
            !children && buttonStyles.iconNoMargin
          )}
        />
      )}
      {children && (
        <Text element="span" className={buttonStyles.text}>
          {children}
        </Text>
      )}
    </>
  )

  classes.push(
    className,
    spacing(padding, margin),
    buttonStyles.button,
    buttonStyles[type],
    isBlock && buttonStyles.block
  )

  return (
    <>
      {to ? (
        <Link
          className={classnames(...classes)}
          to={to}
          onClick={onClick}
          aria-label={ariaLabel}
        >
          <Content />
        </Link>
      ) : (
        <button
          disabled={isDisabled}
          className={classnames(...classes)}
          onClick={onClick}
          aria-label={ariaLabel}
          type={shouldSubmit ? 'submit' : 'button'}
        >
          <Content />
        </button>
      )}
    </>
  )
}

Button.defaultProps = {
  type: 'primary',
  to: null,
  icon: null,
  iconColor: null,
  className: '',
  ariaLabel: '',
  isBlock: false,
  isDisabled: false,
  shouldSubmit: false,
  onClick: null,
  children: null,
}

Button.propTypes = {
  ...spacingPropTypes,
  type: PropTypes.oneOf([
    'primary',
    'secondary',
    'tertiary',
    'destructive',
    'reset',
  ]),
  to: PropTypes.string,
  icon: PropTypes.object,
  iconColor: PropTypes.oneOf(['white', 'black']),
  className: PropTypes.string,
  ariaLabel: PropTypes.string,
  isBlock: PropTypes.bool,
  isDisabled: PropTypes.bool,
  shouldSubmit: PropTypes.bool,
  onClick: PropTypes.func,
  children: PropTypes.node,
}

export default Button
