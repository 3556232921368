import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import overlayStyles from '../../styles/elements/overlay.module.scss'

const Overlay = ({ closeOnClick, children }) => {
  const [animationClass, setAnimationClass] = useState('')

  let overlayRef
  let focusDivRef

  const getInteractiveElements = e => {
    const interactiveElements = e.querySelectorAll(
      'a, input, button, select, textarea'
    )
    const enabledElements = []

    Array.from(interactiveElements).forEach(el => {
      if (!el.disabled) enabledElements.push(el)
    })

    return enabledElements
  }

  const handleKeydown = e => {
    const enabledElements = getInteractiveElements(overlayRef)
    const firstElement = enabledElements[0]
    const lastElement = enabledElements[enabledElements.length - 1]
    const keyCodes = {
      escape: 27,
      tab: 9,
    }

    if (e.shiftKey && e.which === keyCodes.tab) {
      if (firstElement === document.activeElement) {
        setTimeout(() => lastElement.focus(), 1)
      }
    } else if (e.which === keyCodes.tab) {
      if (lastElement === document.activeElement) {
        setTimeout(() => firstElement.focus(), 1)
      }
    } else if (e.which === keyCodes.escape) {
      animateClose()
    }
  }

  useEffect(() => {
    setAnimationClass(overlayStyles.isLoaded)
    focusDivRef.focus()
    document
      .getElementsByTagName('body')[0]
      .classList.add(overlayStyles.overlayOpen)

    return () =>
      document
        .getElementsByTagName('body')[0]
        .classList.remove(overlayStyles.overlayOpen)
  }, [])

  const animateClose = () => {
    setAnimationClass(overlayStyles.isUnloading)
    setTimeout(() => {
      closeOnClick()
    }, 200)
  }

  return (
    <div
      onClick={animateClose}
      className={classnames(overlayStyles.overlay, animationClass)}
      ref={ref => (overlayRef = ref)}
      onKeyDown={handleKeydown}
      role="dialog"
    >
      <div ref={ref => (focusDivRef = ref)} tabIndex={0} />
      {children}
    </div>
  )
}

Overlay.defaultProps = {
  closeOnClick: null,
}

Overlay.propTypes = {
  closeOnClick: PropTypes.func,
  children: PropTypes.node.isRequired,
}

export default Overlay
