import React, { useState } from 'react'
import PropTypes from 'prop-types'
import * as classnames from 'classnames'

import Hidden from '@material-ui/core/Hidden'

import Container from '../../elements/Container/Container'
import Link from '../../elements/Link/Link'
import Text from '../../elements/Text/Text'
import SideNavigation from '../SideNavigation/SideNavigation'
import Button from '../../elements/Button/Button'

import headerStyles from '../../styles/components/header.module.scss'
import logo from '../../images/logos/enquirehub.png'
import { Menu } from '@material-ui/icons'

const Header = ({ isTransparent, isFixed }) => {
  const [isNavigationOpen, setNavigationOpen] = useState(false)

  const toggleNavigation = () => {
    setNavigationOpen(!isNavigationOpen)
  }

  return (
    <header
      className={classnames(
        headerStyles.header,
        isTransparent && headerStyles.transparent,
        isFixed && headerStyles.fixed
      )}
    >
      <Container>
        <nav className={headerStyles.nav}>
          <div className={headerStyles.logoWrapper}>
            <Link to="/">
              <img
                className={headerStyles.logo}
                alt="enquirehub logo"
                src={logo}
              />
            </Link>
          </div>
          <Hidden smDown>
            <Text
              element="span"
              isUppercase
              letterSpacing={3}
              size="small"
              weight="light"
              color="white"
              className={headerStyles.link}
            >
              <Link to="/" color="white">
                Home
              </Link>
            </Text>
            <Text
              element="span"
              isUppercase
              letterSpacing={3}
              size="small"
              weight="light"
              color="white"
              className={headerStyles.link}
            >
              <Link to="/about" color="white">
                About
              </Link>
            </Text>
            <Text
              element="span"
              isUppercase
              letterSpacing={3}
              size="small"
              weight="light"
              color="white"
              className={headerStyles.link}
            >
              <Link to="/faq" color="white">
                FAQ
              </Link>
            </Text>
          </Hidden>
          <Button
            icon={Menu}
            type="secondary"
            className={headerStyles.menuButton}
            onClick={() => toggleNavigation()}
          >
            Menu
          </Button>
          {isNavigationOpen && (
            <SideNavigation closeOnClick={toggleNavigation} />
          )}
        </nav>
      </Container>
    </header>
  )
}

Header.defaultProps = {
  isTransparent: false,
  isFixed: false,
}

Header.propTypes = {
  isTransparent: PropTypes.bool,
  isFixed: PropTypes.bool,
}

export default Header
