import React from 'react'
import PropTypes from 'prop-types'

import Container from '../../elements/Container/Container'
import { Parallax } from 'react-parallax'

import typographyStyles from '../../styles/global/typography.module.scss'
import sectionStyles from '../../styles/components/section.module.scss'

import * as classnames from 'classnames'
import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

const Section = ({
  padding,
  margin,
  backgroundImage,
  isBackgroundParallax,
  isBackgroundFixed,
  hasBackgroundOverlay,
  isBackgroundGradient,
  backgroundColor,
  alignText,
  children,
}) => {
  let additionalProps = {}
  let classes = []

  if (backgroundImage) {
    additionalProps.style = { backgroundImage: `url('${backgroundImage}')` }

    if (isBackgroundFixed && !isBackgroundParallax) {
      classes.push(sectionStyles.bgFixed)
    }
  } else if (backgroundColor) {
    classes.push(sectionStyles[backgroundColor])
  } else if (isBackgroundGradient) {
    classes.push(sectionStyles.bgGradient)
  }

  const Content = () => (
    <>
      {backgroundImage && hasBackgroundOverlay && (
        <div className={sectionStyles.overlay} />
      )}
      <Container className={sectionStyles.content}>{children}</Container>
    </>
  )

  return (
    <section
      className={classnames(
        spacing(padding, margin),
        sectionStyles.section,
        typographyStyles[alignText],
        classes
      )}
      {...additionalProps}
    >
      {backgroundImage && isBackgroundParallax ? (
        <Parallax strength={500} bgImage={backgroundImage}>
          <Content />
        </Parallax>
      ) : (
        <Content />
      )}
    </section>
  )
}

Section.defaultProps = {
  backgroundImage: null,
  isBackgroundParallax: false,
  isBackgroundFixed: false,
  hasBackgroundOverlay: false,
  isBackgroundGradient: false,
  backgroundColor: null,
  alignText: null,
}

Section.propTypes = {
  ...spacingPropTypes,
  backgroundImage: PropTypes.node,
  isBackgroundParallax: PropTypes.bool,
  isBackgroundFixed: PropTypes.bool,
  hasBackgroundOverlay: PropTypes.bool,
  isBackgroundGradient: PropTypes.bool,
  backgroundColor: PropTypes.oneOf([
    'black',
    'white',
    'primary',
    'secondary',
    'grey',
    'lightGrey',
    'midGrey',
    'lightToMidGrey',
    'darkGrey',
  ]),
  alignText: PropTypes.oneOf(['left', 'center', 'right']),
  children: PropTypes.node.isRequired,
}

export default Section
