import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import Overlay from '../../elements/Overlay/Overlay'
import Text from '../../elements/Text/Text'
import Link from '../../elements/Link/Link'
import Button from '../../elements/Button/Button'

import sideNavigationStyles from '../../styles/components/side-navigation.module.scss'
import logo from '../../images/logos/enquirehub.png'
import {
  Home,
  Info,
  Close,
  ContactSupport,
  Facebook,
  Instagram,
  Twitter,
} from '@material-ui/icons'

const SideNavigation = ({ closeOnClick }) => {
  const [animationClass, setAnimationClass] = useState('')

  useEffect(() => {
    setAnimationClass(sideNavigationStyles.isLoaded)
  }, [])

  const animateClose = () => {
    setAnimationClass(sideNavigationStyles.isUnloading)

    setTimeout(() => {
      closeOnClick()
    }, 200)
  }

  return (
    <Overlay closeOnClick={animateClose}>
      <nav
        onClick={e => e.stopPropagation()}
        className={classnames(
          sideNavigationStyles.sideNavigation,
          animationClass
        )}
      >
        <div className={sideNavigationStyles.header}>
          <Link to="/" className={sideNavigationStyles.headerLink}>
            <img
              className={sideNavigationStyles.logo}
              alt="enquirehub logo"
              src={logo}
            />
          </Link>
          <Button
            icon={Close}
            iconColor="white"
            onClick={animateClose}
            type="reset"
            ariaLabel="Close menu"
          />
        </div>
        <Text
          color="white"
          letterSpacing={2}
          weight="light"
          className={sideNavigationStyles.link}
        >
          <Link to="/">
            <Home /> Home
          </Link>
        </Text>
        <Text
          color="white"
          letterSpacing={2}
          weight="light"
          className={sideNavigationStyles.link}
        >
          <Link to="/about">
            <Info /> About
          </Link>
        </Text>
        <Text
          color="white"
          letterSpacing={2}
          weight="light"
          className={sideNavigationStyles.link}
        >
          <Link to="/faq">
            <ContactSupport /> FAQ
          </Link>
        </Text>

        <div className={sideNavigationStyles.socialLinkWrapper}>
          <Link
            to="https://www.facebook.com/enquirehub/"
            isNewWindow
            color="white"
          >
            <Facebook className={sideNavigationStyles.socialLink} />
          </Link>
          <Link to="https://twitter.com/enquirehub" isNewWindow color="white">
            <Twitter className={sideNavigationStyles.socialLink} />
          </Link>
          <Link
            to="https://www.instagram.com/enquirehub/"
            isNewWindow
            color="white"
          >
            <Instagram className={sideNavigationStyles.socialLink} />
          </Link>
        </div>
      </nav>
    </Overlay>
  )
}

SideNavigation.propTypes = {
  closeOnClick: PropTypes.func.isRequired,
}

export default SideNavigation
