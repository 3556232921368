import React from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import { Link as GatsbyLink } from 'gatsby'

import linkStyles from '../../styles/elements/link.module.scss'
import typographyStyles from '../../styles/global/typography.module.scss'
import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'

const Link = ({
  to,
  padding,
  margin,
  color,
  className,
  ariaLabel,
  underline,
  isNewWindow,
  children,
}) => {
  let classes = [
    className,
    spacing(padding, margin),
    linkStyles.link,
    typographyStyles[color],
    linkStyles[underline],
  ]

  return (
    <>
      {isNewWindow ? (
        <a
          className={classnames(classes)}
          href={to}
          target="_blank"
          rel="noopener noreferrer"
          aria-label={ariaLabel}
        >
          {children}
        </a>
      ) : (
        <GatsbyLink
          className={classnames(classes)}
          to={to}
          aria-label={ariaLabel}
        >
          {children}
        </GatsbyLink>
      )}
    </>
  )
}

Link.defaultProps = {
  color: null,
  className: '',
  isNewWindow: null,
  ariaLabel: '',
  underline: 'hoverOnly',
}

Link.propTypes = {
  ...spacingPropTypes,
  to: PropTypes.string.isRequired,
  color: PropTypes.oneOf([
    'black',
    'white',
    'primary',
    'secondary',
    'grey',
    'lightGrey',
    'darkGrey',
  ]),
  className: PropTypes.string,
  isNewWindow: PropTypes.bool,
  ariaLabel: PropTypes.string,
  underline: PropTypes.oneOf(['hoverOnly', 'always', 'reverse']),
  children: PropTypes.node.isRequired,
}

export default Link
