import React from 'react'
import PropTypes from 'prop-types'

import * as classnames from 'classnames'

import containerStyles from '../../styles/elements/container.module.scss'

const Container = ({ className, children }) => (
  <div className={classnames(className, containerStyles.container)}>
    {children}
  </div>
)

Container.defaultProps = {
  className: '',
}

Container.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Container
