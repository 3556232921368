import React from 'react'

import PropTypes from 'prop-types'

import Header from '../../components/Header/Header'
import Footer from '../../components/Footer/Footer'

import '../../styles/normalize.scss'

const MainLayout = ({ isHeaderFixed, isHeaderTransparent, children }) => {
  return (
    <div>
      <Header isFixed={isHeaderFixed} isTransparent={isHeaderTransparent} />

      <main>{children}</main>

      <Footer />
    </div>
  )
}

MainLayout.defaultProps = {
  isHeaderFixed: false,
  isHeaderTransparent: false,
}

MainLayout.propTypes = {
  isHeaderFixed: PropTypes.bool,
  isHeaderTransparent: PropTypes.bool,
  children: PropTypes.node.isRequired,
}

export default MainLayout
