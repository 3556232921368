import React from 'react'
import PropTypes from 'prop-types'

import spacing, { spacingPropTypes } from '../../utils/spacing/spacing'
import * as classnames from 'classnames'

const Spacer = ({ padding, margin, element, className, children }) => {
  const Element = element

  return (
    <Element className={classnames(spacing(padding, margin), className)}>
      {children}
    </Element>
  )
}

Spacer.defaultProps = {
  element: 'div',
  className: '',
}

Spacer.propTypes = {
  ...spacingPropTypes,
  element: PropTypes.oneOf(['div', 'section', 'aside']),
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Spacer
