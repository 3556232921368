import PropTypes from 'prop-types'

import spacingStyles from '../../styles/global/spacing.module.scss'

// TODO: Make spacing work with responsive styles
// const BREAKPOINTS = ['xs', 'sm', 'md', 'lg', 'xl']
// const DIRECTIONS = ['top', 'right', 'bottom', 'left']

const SIZES = [
  'zero',
  'xxSmall',
  'xSmall',
  'small',
  'medium',
  'large',
  'xLarge',
  'xxLarge',
]

const spacing = (padding, margin) => {
  // If no padding or margin passed in do nothing
  if (typeof padding === 'undefined' && typeof margin === 'undefined')
    return null

  let spacingClasses = []

  const calculateStyles = (spacingSizes, property) => {
    if (typeof spacingSizes === 'object') {
      // We're getting passed spacing with breakpoints
      return spacingStyles[spacingSizes]
    }

    if (typeof spacingSizes === 'string') {
      // Split the spacing values into array so it's easier to manage with max of 4 values
      let arrayOfSizes = spacingSizes.replace(/0/gi, 'zero')
      arrayOfSizes = arrayOfSizes.split(' ', 4)

      if (arrayOfSizes.length > 1) {
        switch (arrayOfSizes.length) {
          case 2:
            spacingClasses.push(
              spacingStyles[`${arrayOfSizes[0]}-${property}-top`],
              spacingStyles[`${arrayOfSizes[1]}-${property}-right`],
              spacingStyles[`${arrayOfSizes[0]}-${property}-bottom`],
              spacingStyles[`${arrayOfSizes[1]}-${property}-left`]
            )
            break
          case 3:
            spacingClasses.push(
              spacingStyles[`${arrayOfSizes[0]}-${property}-top`],
              spacingStyles[`${arrayOfSizes[1]}-${property}-right`],
              spacingStyles[`${arrayOfSizes[2]}-${property}-bottom`],
              spacingStyles[`${arrayOfSizes[1]}-${property}-left`]
            )
            break
          default:
            spacingClasses.push(
              spacingStyles[`${arrayOfSizes[0]}-${property}-top`],
              spacingStyles[`${arrayOfSizes[1]}-${property}-right`],
              spacingStyles[`${arrayOfSizes[2]}-${property}-bottom`],
              spacingStyles[`${arrayOfSizes[3]}-${property}-left`]
            )
        }
      } else {
        // Manipulate all values the same
        spacingClasses.push(spacingStyles[`${arrayOfSizes}-${property}`])
      }
    }
  }

  if (typeof padding !== 'undefined') {
    calculateStyles(padding, 'padding')
  }

  if (typeof margin !== 'undefined') {
    calculateStyles(margin, 'margin')
  }

  return spacingClasses
}

const spacingPropType = [
  PropTypes.oneOf(SIZES),
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.oneOf(SIZES)),
]

const spacingPropArray = [
  PropTypes.oneOf(SIZES),
  PropTypes.string,
  PropTypes.arrayOf(PropTypes.oneOf(SIZES)),
  PropTypes.shape({
    xs: PropTypes.oneOfType(spacingPropType),
    sm: PropTypes.oneOfType(spacingPropType),
    md: PropTypes.oneOfType(spacingPropType),
    lg: PropTypes.oneOfType(spacingPropType),
    xl: PropTypes.oneOfType(spacingPropType),
  }),
]

export const spacingPropTypes = {
  padding: PropTypes.oneOfType(spacingPropArray),
  margin: PropTypes.oneOfType(spacingPropArray),
}

export default spacing
