import React from 'react'

import Grid from '@material-ui/core/Grid'

import Container from '../../elements/Container/Container'
import Link from '../../elements/Link/Link'
import Heading from '../../elements/Heading/Heading'
import Text from '../../elements/Text/Text'
import Spacer from '../../elements/Spacer/Spacer'

import footerStyles from '../../styles/components/footer.module.scss'
import logo from '../../images/logos/enquirehub.png'
import { Facebook, Twitter, Instagram } from '@material-ui/icons'

const Footer = () => {
  return (
    <footer className={footerStyles.footer}>
      <Container>
        <Grid container>
          <Grid item xs={12} md={3}>
            <Spacer margin="0 0 large">
              <Link to="/">
                <img alt="enquirehub logo" src={logo} />
              </Link>
            </Spacer>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Spacer margin="0 0 large">
              <Heading level={4} color="white" weight="light">
                Links
              </Heading>
              <Text size="medium">
                <Link to="/" color="white">
                  Home
                </Link>
              </Text>
              <Text size="medium">
                <Link to="/about" color="white">
                  About
                </Link>
              </Text>
              <Text size="medium">
                <Link to="/faq" color="white">
                  FAQ
                </Link>
              </Text>
            </Spacer>
          </Grid>
          <Grid item xs={12} sm={4} md={3}>
            <Spacer margin="0 0 large">
              <Heading level={5} color="white" weight="light">
                Social
              </Heading>
              <Link
                to="https://www.facebook.com/enquirehub/"
                isNewWindow
                color="white"
                margin="0 xSmall 0 0"
              >
                <Facebook className={footerStyles.socialLink} />
              </Link>
              <Link
                to="https://twitter.com/enquirehub"
                isNewWindow
                color="white"
                margin="0 xSmall 0 0"
              >
                <Twitter className={footerStyles.socialLink} />
              </Link>
              <Link
                to="https://www.instagram.com/enquirehub/"
                isNewWindow
                color="white"
                margin="0 xSmall 0 0"
              >
                <Instagram className={footerStyles.socialLink} />
              </Link>
            </Spacer>
          </Grid>
          <Grid item xs={12} sm={4} md={3}></Grid>
          <Grid item xs={12}>
            <Text color="white" size="small" align="center" margin="0">
              © {new Date().getFullYear()} enquirehub
            </Text>
          </Grid>
        </Grid>
      </Container>
    </footer>
  )
}

export default Footer
